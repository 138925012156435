import styled from 'styled-components';

import { toRem, breakpoints } from 'utils/mixins';
import { container, space } from 'theme/sizes';
import { neutral } from 'theme/colors';

export const Container = styled.div`
  max-width: ${container.xsmall};
  height: 600px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  .map__thumb {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: ${space.big};
    background: ${neutral.neu02};
    z-index: 2;
    transform: translateX(100%);
    transition: 220ms;

    &--active {
      transform: translateX(0);
    }
  }

  .map__image {
    width: ${space.big};
    height: ${toRem(300)};
  }

  .map__info {
    height: ${toRem(300)};
    padding: ${space.xs};

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        padding: ${space.xxs};
        margin-left: ${space.xxs};
      }
    }

    &-inner {
      height: 100%;
      padding: ${space.xxs};
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: ${space.xxs};
        background: transparent;
      }

      &::-webkit-scrollbar-track {
        border-radius: ${space.xxs};
      }

      &::-webkit-scrollbar-thumb {
        border-radius: ${space.xxs};
        background: ${neutral.neu08};
      }

      ul {
        margin-top: ${space.xs};
        padding-left: ${space.xs};

        li {
          font-size: ${toRem(16)};
        }
      }

      a {
        display: block;
        margin-top: auto;
      }
    }
  }

  .map__close {
    position: absolute;
    top: ${space.xs};
    left: ${space.xs};
    color: ${neutral.neu01};
    font-size: ${space.s};
    padding: ${space.xxs};

    &--relative {
      position: relative;
    }
  }

  @media ${breakpoints.bigDesktop} {
    max-width: ${container.default};
  }
`;
