import React from 'react';

import { BsXCircleFill } from 'react-icons/bs';
import { FaDirections } from 'react-icons/fa';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { Container } from './Map.styles';
import { GatsbyImage } from 'gatsby-plugin-image';

const mapStyle = [
  {
    featureType: 'all',
    elementType: 'geometry.fill',
    stylers: [
      {
        weight: '2.00',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#9c9c9c',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7b7b7b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#46bcec',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#b5dae1',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#070707',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
];

const CMap = (props) => {
  // PROPS
  const {
    data: { map, markers },
  } = props;
  // PROPS

  // STATE
  const [info, setInfo] = React.useState(null);
  // STATE

  return (
    <Container>
      <div className={info ? 'map__thumb map__thumb--active' : 'map__thumb'}>
        {info?.local?.image && (
          <div className="map__image">
            <GatsbyImage
              image={
                info?.local?.image.localFile?.childImageSharp?.gatsbyImageData
              }
              alt={info?.local?.image?.altText}
            />
          </div>
        )}
        <button
          className={
            info?.local?.image
              ? 'map__close'
              : 'map__close  map__close--relative'
          }
          onClick={() => setInfo(null)}
        >
          <BsXCircleFill fill={!info?.local?.image && 'black'} />
        </button>
        <div className="map__info">
          <div className="map__info-inner">
            <div className="map__info-header">
              <h4>{info?.title}</h4>
              <a href={info?.local?.directions}>
                <FaDirections />
              </a>
            </div>
            <p>Colaboradores:</p>
            <ul>
              {info?.local?.colaboradores?.map((col, index) => {
                return <li key={index}>{col.staff.name}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
      <LoadScript googleMapsApiKey="AIzaSyBrThegqkfH60OqxXqZ1HTE-eh-Kwzd43g">
        <GoogleMap
          options={{ styles: mapStyle }}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={{ lat: Number(map.lat), lng: Number(map.lng) }}
          zoom={7}
        >
          {markers.map((marker, index) => {
            const {
              node: {
                local: {
                  colaboradores,
                  coordinates: { latitude, longitude },
                  description,
                  image,
                },
                title,
              },
            } = marker;

            if (latitude) {
              return (
                <Marker
                  title={title}
                  icon="/location-pin.png"
                  position={{
                    lat: Number(latitude),
                    lng: Number(longitude),
                  }}
                  onClick={() => {
                    info ? setInfo(null) : setInfo({ ...marker.node });
                  }}
                />
              );
            }
          })}
        </GoogleMap>
      </LoadScript>
    </Container>
  );
};
export default React.memo(CMap);
