import React from 'react';

import { graphql } from 'gatsby';

import Hero from 'components/hero';
import Feature from 'components/feature';
import Map from 'components/map';
import CTA from 'components/cta';

const EmpresaTemplate = (props) => {
  //PROPS
  const {
    data: {
      allWpLocal,
      page: {
        hero: { title, text, subtitle },
        empresa_template: { map, mapContent, mapSubtitle, mapTitle, banner },
        cta,
      },
    },
  } = props;
  //PROPS

  return (
    <main className="main">
      <Hero data={{ title, subtitle, text }} center />
      {banner.map((feature, index) => {
        return <Feature {...feature} key={index}/>;
      })}

      <Hero
        data={{ title: mapTitle, subtitle: mapSubtitle, text: mapContent }}
      />
      <Map data={{ map, markers: allWpLocal.edges }} />
      <CTA data={cta} />
    </main>
  );
};

export const query = graphql`
  query Empresa($id: String) {
    allWpLocal {
      edges {
        node {
          local {
            colaboradores {
              ... on WpColaborador {
                staff {
                  name
                }
              }
            }
            coordinates {
              latitude
              longitude
            }
            description
            directions
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 360
                    aspectRatio: 1.2
                  )
                }
              }
            }
          }
          title
        }
      }
    }
    page: wpPage(id: { eq: $id }) {
      title
      id
      hero {
        title
        text
        subtitle
      }
      empresa_template {
        banner {
          featureImageFirst
          featureSubtitle
          featureText
          featureTitle
          featureImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 1.154929577464789
                )
              }
            }
          }
        }
        map {
          lng
          lat
        }
        mapContent
        mapSubtitle
        mapTitle
      }
      cta {
        ctaSubtitle
        ctaTitle
        type
        ctaCta {
          url
          title
          target
        }
        ctaImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default EmpresaTemplate;
